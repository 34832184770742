/** Overrides and tweaks to PrimeNG styles */

// Use up all available width


// Override PrimeNG's default style to match bootstrap
.p-dropdown,
.p-checkbox>.p-checkbox-box,
.p-multiselect,
.p-autocomplete>ul.p-inputtext,
.p-autocomplete>input.p-inputtext,
.p-calendar>input {
  border-color: var(--bs-border-color);
  // border-radius: var(--bs-border-radius);
  border: 1px solid #a6a6a6;
}

.p-dropdown {
  width: 130px;
}

.p-multiselect {
  width: 160px;
}

.p-icon-wrapper {
  pointer-events: unset !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
  margin-left: -0.5rem;
}

.p-multiselect-panel .p-multiselect-header {
  padding: .429rem .3674rem;
}

//prime ng  style overrides
.p-inputtext,
.p-dropdown,
.p-multiselect,
.p-inputswitch {
  border-radius: 0px !important;
  box-shadow: none !important;
  border-color: #a6a6a6;
}

.p-inputswitch.p-focus .p-inputswitch-slider,
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
  outline: none;
  border-color: #a6a6a6;
}

textarea {
  resize: none;
}

// Use Corteva's preferred font
:root {
  --font-family: var(--res-body-font-family);
}


.p-accordion-tab {
  margin-bottom: 20px;
}

.p-calendar {
  width: 100%;
}

.p-calendar>input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:not(.p-dialog-footer)>.p-button :not(.p-selectbutton)>.p-button {
  border: #a9a9a9;
  background: #a9a9a9;
}

.p-column-filter-buttonbar>.p-button,
.p-column-filter-add-rule>.p-button {
  background: var(--bs-primary);
  color: var(--bs-body-bg);
}

.p-column-filter-buttonbar>.p-button-outlined {
  border: var(--bs-primary) solid 1px;
  background: var(--bs-body-bg);
  color: var(--bs-primary);
}

.p-paginator {
  justify-content: end;
  padding-right: 10px;
}

.p-datatable-table {
  margin: 0;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--bs-success);
}